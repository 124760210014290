<template>
  <div>
    <CRow>
      <CCol class="col-12 col-sm-2 mt-2">
        <select class="custom-select" v-model="searchBy">
          <option value="">{{ $t('allProduct') }}</option>
          <option value="ProductPLU.name">{{ $t('productName') }}</option>
          <option value="ProductPLU.PLUCode">{{ $t('pluCode') }}</option>
          <option value="Category.objectId" selected>
            {{ $t('category') }}
          </option>
          <option value="ProductSKU.skuType">{{ $t('productType') }}</option>
        </select>
      </CCol>
      <CCol class="col-12 col-sm-8 mt-2">
        <select
          v-if="searchBy === 'ProductSKU.skuType'"
          class="custom-select"
          v-model="keyword"
        >
          <option value="" >{{ $t('selectProductType') }}</option>
          <option value="P" selected>{{ $t('productTypeP') }}</option>
          <option value="BOM">{{ $t('productTypeBOM') }}</option>
          <option value="SN">{{ $t('productTypeSN') }}</option>
          <option value="SV">{{ $t('productTypeSV') }}</option>
        </select>
        <multiselect
          v-else-if="searchBy === 'Category.objectId'"
          v-model="value"
          :options="categoryList"
          :multiple="true"
          :search="true"
          :showLabels="false"
          :close-on-select="false"
          :clear-on-select="false"
          :placeholder="$t('selectCategory')"
          label="name"
          track-by="name"
          :group-select="false"
          @input="updateKeyword"
        >
          <template
          slot="selection"
            style="height: 35px; width: -2em;"
            slot-scope="{ values, isOpen }"
          >
            <span v-if="values.length && !isOpen">
              {{ values.length }} {{ $t('category') }}
            </span>
          </template>
        </multiselect>
        <div v-else>
          <input v-if="searchBy === ''" class="form-control" disabled
          :placeholder="$t('dialogSelectCategory')" />
          <input
            v-else
            v-model="keyword"
            class="form-control"
            @keyup.enter="getSort(searchBy, keyword)"
            :placeholder="$t('keyword')"
          />
        </div>
      </CCol>
      <CCol class="col-12 col-sm-2 mt-2">
        <CButton @click="getSort(searchBy, keyword)" color="success" block>
          {{ $t('search') }}
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import pos from '@/services/local'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      searchBy: this.$route.query.searchBy || '',
      category: [],
      dataCategory: [],
      value: [],
      keyword: this.$route.query.keyword || '',
    }
  },
  created() {
    this.getCategory()
    if (this.keyword) {
      this.value = this.$route.query.keyword.split(',') || []
    }
  },
  computed: {
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    categoryList() {
      let data = this.dataCategory
      let detail = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].enabled === true) {
          detail.push({
            id: data[i].id,
            name: data[i].name,
            objectId: data[i].objectId,
          })
        }
      }
      return detail
    },
  },
  methods: {
    updateKeyword(keywordlists) {
      let appv = []
      keywordlists.forEach((word) => {
        appv.push(word.objectId)
      })

      this.keyword = appv.toString()
    },
    getSort(searchBy, keyword) {
      this.searchBy = searchBy
      this.keyword = keyword
      this.$emit('getSort', searchBy, keyword)
    },
    getCategory() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      let params = {
        page: 1,
        limit: 1000,
      }

      pos
        .get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId, {
          params,
        })
        .then((res) => {
          this.dataCategory = res.data.data
          let data = this.dataCategory
          for (let i = 0; i < data.length; i++) {
            if (data[i].enabled === true) {
              if (data[i].id == '0') {
                this.category = {
                  id: data[i].id,
                  name: data[i].name,
                  objectId: data[i].objectId,
                }
              }
            }
          }
        })
    },
  },
  watch: {
    searchBy(val) {
      this.keyword = ''
    },
  },
}
</script>
<style src="../assets/styles/multiselect.css"></style>
